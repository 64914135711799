export const EMAIL_LABLE = 'Email';
export const PASSWORD_LABLE = 'Password';
export const TEXT_REMEMBER_ME = 'Remember me';
export const TEXT_WELCOME = 'Welcome';
export const TEXT_FORGOT_PASSWORD = 'Forgot your password?';
export const BTN_LOGIN = 'Login';
export const BTN_SEND_MAIL = 'Send recovery email';
export const UN_AUTH = 'User information not found. Please log in to continue.';
export const HTTP_ERR_NETWORK = 'Can’t connect to server.';
export const FORGOT_PASSWORD_ITEM = {
  TEXT_FORGOT_PASSWORD: 'Forgot your password?',
  BTN_SEND_MAIL: 'Send recovery email',
  MSG_REMEMBER: 'Remembered your password? ',
  TRY_LOGIN_ITEM: 'Try logging in',
  PLEASSE_ENTER_EMAIL: 'Please enter your email address below to recive a password reset link',
  PLEASSE_PASSWORD: 'Please follow the instructions to reset your password.',
  RESET_IN: 'Resend in ',
  RESET_IN_S: 's',
  TEXT_RESET_PASSWORD: 'Reset password',
  TEXT_NEW_PASSWORD: 'New password',
  TEXT_CONFIRM_PASSWORD: 'Confirm password',
  TEXT_RE_ENTER: 'Re-enter password',
  SENT_MAIL_FORGOT: 'An email will be sent to your email address.',
  RESET_SUCCESS: 'Reset password successful!',
  RESET_ERROR: 'Your password reset link has expired.',
};

export const MODULE_DESCRIPTION_KNOW_CARBON = {
  KNOW_CARBON: 'I know the carbon emissions of the source',
  SITE_USAGE: 'Select this option if you have the emissions \nfrom the selected source. Recommended to attach the source of information',
};

export const MSG_WARNING = {
  EMISSION_FACTOR_TITLE: 'It seems that the entered value exceeds the recommended range of values by the system. Do you wish to continue?',
  EMISSION_FACTOR_CONTENT:
    'If you select yes, please, attach supporting document with the source of information the administrator will contact you in case more information needed',
};

// Machinery module
export const CREATE_MACHINERY = {
  SOURCE: {
    OPTIONS: [
      {
        CATEGORY_ID: 1,
        SOURCE_IDS: [10], // Tool id - electricity
      },
      {
        CATEGORY_ID: 2,
        SOURCE_IDS: [1, 10, 2], // Machine id - Gasoline, electricity, diesel
      },
    ],
    OPTIONS_DATA: [1, 10, 2], // Machine id - Gasoline, electricity, diesel
  },
  ATTACH_FILE: {
    ACCEPT_FILES: 'image/*,.svg,.csv,.zip,.excel,.xls,.xlsx,.pdf,.doc,.docx',
  },
  QUANTITY: {
    INIT_VALUE: 1,
    MIN_VALUE: 1,
    MAX_VALUE: 99999999,
    MAX_LENGTH: 11,
    PLACEHOLDER: '1',
  },
  HOURS_OF_OPERATION: {
    INIT_VALUE: 8,
    MIN_VALUE: 1,
    MAX_VALUE: 99999999,
    MAX_LENGTH: 11,
    PLACEHOLDER: 'h/month',
  },
};

// Travelling module
const MODIFIED_TRAVELLING = {
  TRIP_NAME: {
    PLACEHOLDER: 'Enter trip name',
    MAX_LENGTH: 128,
    TOOLTIP: 'Include a proposal unique, descriptive name and location. Example - "Business trip_Boston".',
  },
  INPUT_NUMBERS: {
    INIT_VALUE: 1,
    MIN_VALUE: 1,
    MAX_VALUE: 999,
    MAX_LENGTH: 3,
    PLACEHOLDER: '1',
  },
  HOTEL_ROOM: {
    TOOLTIP: 'For each person considering one room per person.',
    INIT_VALUE: 0,
    MIN_VALUE: 0,
  },
  TRAVEL_BY_AIR: {
    ID: 9,
  },
  TRAVEL_BY_AIR_SHORT: {
    ID: 6,
  },
  TRAVEL_BY_AIR_MID: {
    ID: 7,
  },
  TRAVEL_BY_AIR_LONG: {
    ID: 8,
  },
  TRAVEL_BY_COMMUTER_RAIL: {
    ID: 3,
  },
  TRAVEL_BY_TRANSIT_RAIL: {
    ID: 4,
  },
  TRAVEL_BY_BUS: {
    ID: 5,
  },
  DISTANCE: {
    MAX_VALUE: 99999999,
    MAX_LENGTH: 15,
    TOOLTIP: 'Please enter the one way distance in km.',
  },
  TRIPS: {
    TOOLTIP: 'Enter the total number of one way trips you make for the given distance. Please use whole numbers. For example - enter "5".',
  },
  TRAVEL_DATE: {
    TOOLTIP: 'Enter the date you are reporting travelling for.',
  },
};

Object.freeze(MODIFIED_TRAVELLING);

export { MODIFIED_TRAVELLING };

const INPUT_NUMBERS = {
  INIT_VALUE: 1,
  MIN_VALUE: 1,
  MAX_VALUE: 999,
  MIN_PERCENT: 0,
  MAX_PERCENT: 999,
  MAX_LENGTH: 3,
  PLACEHOLDER: '1',
};

Object.freeze(INPUT_NUMBERS);

export { INPUT_NUMBERS };

export const DEFINE_FILE_NAME = {
  DOWNLOAD_SINGLE_PROJECT: 'Eco_Space_Export_Data_',
  DOWNLOAD_MODULE_CSV: 'Export_Results_CSV',
  ECO_SALE_RESULT_DOWNLOAD_SINGLE_PROJECT: 'Eco_Sales_Export_Data_',
  ECO_SALE_RESULT_DOWNLOAD_WORD_PROJECT: 'Eco_Sales_Export_Data_Word_',
  ECO_SALE_RESULT_DOWNLOAD_PCF_PROJECT: 'Eco_Sales_Export_PCF_',
  ECO_SALE_RESULT_DOWNLOAD_WORD_PCF_PROJECT: 'Eco_Sales_Export_Word_PCF_',
};

export const TYPE_MODULE_ID = {
  SITE_USAGE: 1,
  MATERIALS: 2,
  MACHINERY: 3,
  TRAVELING: 4,
  SHIPPING: 5,
  EQUIPMENT: 6,
  OPERATION: 7,
  END_OF_LIFE: 8,
  SALES_SHIPPING: 9,
};

export const TYPE_MODULE_NAME = {
  SITE_USAGE: 'Site Usage',
  MATERIALS: 'Materials',
  MACHINERY: 'Machinery',
  TRAVELING: 'Travelling',
  SHIPPING: 'Shipping',
  EQUIPMENT: 'Equipment',
  OPERATION: 'Operations',
  END_OF_LIFE: 'End of Life',
};

export const TYPE_MODULE_NAME_ECO_SALES = {
  EQUIPMENT_MATERIAL: 'Equipment material',
  EQUIPMENT_ENERGY: 'Equipment energy',
  OPERATION: 'Operation',
  SHIPPING: 'Shipping',
};

export const NAME_CHART_GLOBAL_WARMING_POTENTIAL = {
  EQUIPMENT_MATERIAL: 'Equipment: Materials',
  EQUIPMENT_ENERGY: 'Equipment: Manufacturing energy',
  OPERATION: 'Operation',
  SHIPPING: 'Shipping',
};

const fool_string_ESLint = '';

export const STRING_MODULE_HOME = {
  SITE_USAGE_FIRST:
    'The Site Usage building block examines the carbon emissions generated by the operational aspects of facilities, including energy use, waste, oil & chemical spills, water usage.',
  SITE_USAGE_LAST:
    'The purpose is to identify critical consumption points and implement energy saving solutions, solutions that improve the environmental impact and solutions that obtain improvements related to other environmental compartments.',
  MATERIALS_FIRST:
    'The Materials building block quantifies the carbon emissions related to the consumables and construction materials. This scope aims to provide a comprehensive view of the environmental impact of the material choices.',
  MATERIALS_LAST:
    'The purpose is to identify high impact materials and facilitate a shift toward more sustainable, renewable, or recycled options.',
  TRAVELING_FIRST:
    'The Traveling building block focuses on the carbon emissions generated from all business-related travel activities, which include flights, ground transportation, employee commuting and stay in hotels.',
  TRAVELING_LAST:
    'The purpose is to give insights travel-related carbon footprint, enabling the implementation of emission-reducing policies like remote work, video conferencing, or greener travel alternatives.',
  MACHINERY_FIRST:
    'The Machinery building block assesses the carbon emissions originating from all industrial machinery used in construction processes, including cranes, forklifts, and electric equipment. This scope gives a comprehensive view of the energy usage tied to operational equipment.',
  MACHINERY_LAST:
    'The purpose is to pinpoint areas of high energy consumption and explore efficient technologies or modify existing systems to minimize emissions.',
  SALES_SHIPPING_FIRST:
    'The Shipping building block evaluates the carbon emissions arising from all transportation activities in the supply chain with a particular focus on equipment transportation. This includes analyzing emissions from diverse transportation modes like trucks, ships, and airplanes.',
  SHIPPING_FIRST:
    'The Shipping building block evaluates the carbon emissions arising from all transportation activities in the supply chain. This includes analyzing emissions from diverse transportation modes like trucks, ships, and airplanes.',
  SHIPPING_LAST: 'The purpose is to identify high-impact areas and explore greener, more efficient shipping alternatives.',
  EQUIPMENT_FIRST:
    'The Equipment building block assesses the carbon emissions originating from all equipment used in the solution, including all phases from its raw material extraction to the energy consumption while manufacturing said equipment.',
  EQUIPMENT_LAST: 'The purpose is to identify equipment of high environmental impact and explore alternatives to minimize emissions.',
  OPERATION_FIRST: `${fool_string_ESLint}The Operations building block evaluates the carbon emissions arising from the use phase of the equipment, including power losses, gas insulation leakages and auxiliaries' consumption in some specific cases.`,
  OPERATION_LAST:
    'The purpose is to identify operational improvements in terms of available technology, energy efficiency or site selection.',
  END_OF_LIFE_FIRST:
    'The End of Life building block help us quantify the carbon emissions depending on the process that the products go through once they have reached their lifetime, such processes can be recycling, reuse, incineration, landfill, etc.',
  END_OF_LIFE_LAST: 'The purpose is to identify and understand the benefits we can obtain from such End of Life practices.',
};

// Analytic page
export const ANALYTICS = {
  FILTER_BAR: {
    LABELS: {
      BUSINESS_UNIT: 'Business unit',
      GLOBAL_PRODUCT_GROUP: 'Global product group',
      BUSINESS_LINE: 'Business line',
      COUNTRY: 'Country',
      YEAR: 'Year',
    },
  },
  ACTION: {
    TYPE: {
      BUSINESS_UNIT: {
        SET: 'set_business_unit',
        EMPTY: 'empty_business_unit',
        MODIFIED: 'modified_business_unit',
      },
      GLOBAL_PRODUCTS: {
        SET: 'set_global_products',
        MODIFIED: 'modified_global_products',
        EMPTY: 'empty_global_products',
      },
      BUSINESS_LINE: {
        SET: 'set_business_line',
        MODIFIED: 'modified_business_line',
        EMPTY: 'empty_business_line',
      },
      COUNTRY: {
        MODIFIED: 'modified_country',
      },
      YEAR: {
        MODIFIED: 'modified_year',
      },
    },
  },
  MAXIMUM_OVERTIME_BREAKDOWN_YEARS: 4,
};

Object.freeze(ANALYTICS);

export const BUSINESS_UNITS = {
  PGGI: {
    ID: 1,
    NAME: 'PGGI – Grid Integration',
  },
  PGTR: {
    ID: 2,
    NAME: 'PGTR – Transformers',
  },
  PGHV: {
    ID: 3,
    NAME: 'PGHV – Hight Voltage Products',
  },
  PGGA: {
    ID: 4,
    NAME: 'PGGA – Grid Automation',
  },
};

export const GLOBAL_PRODUCT_GROUPS = {
  HVDC: {
    ID: 1,
    NAME: 'HVDC',
  },
  GPQSS: {
    ID: 2,
    NAME: 'GPQSS',
  },
  SEMICONDUCTORS: {
    ID: 3,
    NAME: 'Semiconductors',
  },
  TRANSFORMERS: {
    ID: 5,
    NAME: 'Transformers',
  },
  HIGH_VOLTAGE: {
    ID: 6,
    NAME: 'High Voltage',
  },
  GRID_AUTOMATION: {
    ID: 7,
    NAME: 'Grid Automation',
  },
};

Object.freeze(BUSINESS_UNITS);
Object.freeze(GLOBAL_PRODUCT_GROUPS);

export const PROJECT = {
  PROJECT_RESPONSIBLE: {
    FORM_ITEM: {
      PLACEHOLDER: 'abc@gmail.com',
    },
  },
};

Object.freeze(PROJECT);

export const TOOLTIP_MODULE_MODIFIED = {
  PROJECT_INFORMATION_CUSTOMER:
    'Please attach any specification or files that are relevant to the project. This could include design draft, datasets, image or any other documentation.',
};

export const ITEM_TABLE_GRID_EMOTION = {
  NUMBER_OF_HOURS: 'Number of hours (per day)',
  POWER_MIN: 'Power min (kW)',
  POWER_MAX: 'Power max (kW)',
};

export const ACTION_MODULE = {
  PREVIOUS: 'Previous',
  NEXT: 'Next',
};

export const OPERATION_ITEM = {
  I_KNOW_VOLUME_LEAKAGE: 'I know the annual gas leakage',
  I_KNOW_LOSSES_SYSTEM: 'I know the power losses of the system',
};

export const BACKGROUND_COLOR_MODULES = ['rgb(255, 0, 38)', 'rgb(126, 0, 10)', 'rgb(45, 45, 45)', 'rgb(189 190 191)'];

export const STATUS_PROJECT = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
};
export const EQUIPMENT_DC_CHARGING_CABINETS = 'DC charging cabinets';
