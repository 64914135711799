import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../utils/constants/routes';
import BaseLayout from './BaseLayout';
import { Spin } from 'antd';
import { useUndoRedoContext } from 'src/hooks/useUndoRedoContext';

export const publicRoute = [
  {
    path: ROUTES.LOGIN,
    element: lazy(() => import('../../pages/login/index')),
    auth: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: lazy(() => import('../../pages/forgot-password')),
    auth: false,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: lazy(() => import('../../pages/reset-password')),
    auth: false,
  },
  {
    path: ROUTES.CHECK_TOKEN_RESET_PASSWORD,
    element: lazy(() => import('../../pages/reset-password')),
    auth: false,
  },
  {
    path: '/',
    element: lazy(() => import('../../pages/Redirect')),
    auth: false,
  },
  {
    path: '*',
    element: lazy(() => import('../../pages/NotFound')),
    auth: false,
  },
  {
    path: ROUTES.ANALYZE,
    element: lazy(() => import('../../pages/ComingSoon')),
    auth: true,
  },
];

export const privateRoutes = [
  {
    path: ROUTES.HOME,
    element: lazy(() => import('../../pages/Home/index')),
    auth: true,
  },
  {
    path: ROUTES.PROJECT_LIST,
    element: lazy(() => import('../../pages/Project/Index')),
    auth: true,
  },
  {
    path: ROUTES.PROJECT_CREATE,
    element: lazy(() => import('../../pages/Project/Create')),
    auth: true,
  },
  {
    path: ROUTES.PROJECT_DETAIL,
    element: lazy(() => import('../../pages/Project/Detail')),
    auth: true,
  },
  {
    path: ROUTES.PROJECT_EDIT,
    element: lazy(() => import('../../pages/Project/Update')),
    auth: true,
  },
  {
    path: ROUTES.RESULTS,
    element: lazy(() => import('../../pages/Result/Results')),
    auth: true,
  },
  {
    path: ROUTES.CONTACT,
    element: lazy(() => import('../../pages/Contact/Index')),
    auth: true,
  },
  {
    path: ROUTES.INFORMATION,
    element: lazy(() => import('../../pages/Information/index')),
    auth: true,
  },
  {
    path: ROUTES.TUTORIAL,
    element: lazy(() => import('../../pages/Tutorial/Index')),
    auth: true,
  },
  {
    path: ROUTES.PROJECT_MODULES,
    element: lazy(() => import('../../pages/Project/sub-project/Index')),
    auth: true,
  },
  {
    path: ROUTES.REPORT_SINGLE,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Single/PdfTemplate')),
  },
  {
    path: ROUTES.REPORT_SINGLE_WORD,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Single/WordTemplate')),
  },
  {
    path: ROUTES.REPORT_COMPARISON,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Comparison/PdfTemplate')),
  },
  {
    path: ROUTES.REPORT_COMPARISON_WORD,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Comparison/WordTemplate')),
  },
  {
    path: ROUTES.REPORT_PCF_PDF,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Pcf/PdfTemplate')),
  },
  {
    path: ROUTES.REPORT_PCF_WORD,
    element: lazy(() => import('../../pages/Result/ReportTemplate/Pcf/WordTemplate')),
  },
];

const ConfigRoutes = () => {
  const { undo, redo } = useUndoRedoContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for Ctrl or Cmd key
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;

      if (isCtrlOrCmd && event.key === 'z') {
        undo();
        event.preventDefault();
      }

      if (isCtrlOrCmd && event.key === 'y') {
        redo();
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Routes>
      <Route element={<BaseLayout />}>
        {privateRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={
                <React.Suspense
                  fallback={
                    <Spin spinning wrapperClassName="spinning-route">
                      <div
                        style={{
                          width: '100vw',
                          height: '100vh',
                        }}
                      />
                    </Spin>
                  }
                >
                  {<route.element />}
                </React.Suspense>
              }
              key={index}
            ></Route>
          );
        })}
      </Route>
      {publicRoute.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <React.Suspense
              fallback={
                <Spin spinning wrapperClassName="spinning-route">
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                    }}
                  />
                </Spin>
              }
            >
              <route.element />
            </React.Suspense>
          }
        ></Route>
      ))}
    </Routes>
  );
};

export default ConfigRoutes;
